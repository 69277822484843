import i18n from "i18next";
import { initReactI18next } from "react-i18next"
import locales from '@/locales/locales'
import antEnUS from 'antd-mobile/es/locales/en-US'
import antZhCN from 'antd-mobile/es/locales/zh-CN'
import antArSA from 'antd-mobile/es/locales/ar-SA'
import antTrTR from 'antd-mobile/es/locales/tr-TR'
import storageUtil from '@/utils/localStorage'

const { getLocalStorage } = storageUtil

/* 获取msg */
const getMsg = (locale: 'zh_CN' | 'en_US' | 'ar_SA' | 'tr_TR') => {
  const msg: { [key: string]: string } = {};

  // 中文取key
  if (locale === 'zh_CN') {
    Object.keys(locales).forEach((key) => {
      msg[key] = key;
    });
    return msg;
  }

  // 其他取value
  Object.keys(locales).forEach((localeKey: string) => {
    msg[localeKey] = locales[localeKey][locale];
  });
  return msg;
};

// i18n的语言资源map
const resources = {
  zh: {
    translation: getMsg('zh_CN'),
  },
  en: {
    translation: getMsg('en_US'),
  },
  tr: {
    translation: getMsg('tr_TR'),
  },
  ar: {
    translation: getMsg('ar_SA'),
  },
};

// antd-mobile的语言文件map
const antResources: { [key: string]: any } = {
  en: antEnUS,
  zh: antZhCN,
  ar: antArSA,
  tr: antTrTR,
}

// -连接符语言转换为i18n语言
const lang2I18nMap: any = {
  'zh-CN': 'zh',
  'en-US': 'en',
  'ar-SA': 'vi',
  'tr-TR': 'tr'
}

// 获取i18n的语言
export function getI18nLang() {
  const lang = getLocalStorage('language') || ''
  const systemLang = getSystemLang()
  return lang || lang2I18nMap[systemLang]
}

/**
 * 获取antd-mobile的语言文件
 * @returns 
 */
export function getAntLangFile() {
  const language: string = getI18nLang()
  return antResources[language] || antResources.en
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getI18nLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

/**
 * 获取浏览器默认的语言，并转换为通用-连接符格式
 * @returns
 */
export function getSystemLang() {
  // @ts-ignore
  let lang = window.navigator.language || window.navigator.userLanguage || 'zh-CN'
  const map: any = {
    en: 'en-US',
    vi: 'vi-VN',
    ru: 'ru-RU',
  }
  // 如果不是-链接的，转为-链接的
  if (!lang.includes('-')) {
    if (map[lang]) {
      lang = map[lang]
    } else {
      lang = `${lang}-${lang.toUpperCase()}`
    }
  }
  return lang
}

export const langOptions = [
  { text: 'English', key: 'en-US' },
  { text: '中文', key: 'zh-CN' },
  { text: 'العربية', key: 'ar-SA' },
  { text: 'Türkçe', key: 'tr-TR' },
]

export type LangOption = typeof langOptions[0]

export default i18n;
export const t = i18n.t
  