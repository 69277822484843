import 'react'
import axios from 'axios'
import { useRequest } from 'ahooks'
// import { Toast } from 'antd-mobile'
import { getConfig } from '@/config'
import utils from '@/utils/utils'
import storageUtil from '@/utils/localStorage'
import brower from '@/utils/brower';
import dsBridge from 'dsbridge';
import type { InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios'
// @ts-ignore
import type { UseRequestHttpResult, AxiosConfig } from './types'
import type { IUserDeviceInfo } from '@/types/common'

const conf = getConfig()
const { isAndroid } = brower;
const { isExist, onlyLastRequest, to } = utils
const { getLocalStorage } = storageUtil

// 基础的请求选项
const baseRequestOptions = {
  manual: true,
  showMessage: true,
}

// 防止重复弹窗的变量
// let showTokenExpiredDialog = false

// 创建请求实例，请求拦截
const instance = axios.create()
instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (!config) {
    config = {} as InternalAxiosRequestConfig
  }

  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders
  }
  config.baseURL = conf.rootPath

  let mac = '';
  let version = '';
  let deviceModel = '';
  let systemVersion = '';
  let userDeviceInfo: IUserDeviceInfo = {
    mac: '',
    version: '',
    deviceModel: '',
    systemVersion: '',
  };
  const uid = getLocalStorage('uid')
  const accesstoken = getLocalStorage('accessToken') || '';
  const platform = isAndroid() ? 2 : 1;

  if (dsBridge.hasNativeMethod('getuserDeviceInfo')) {
    const value = dsBridge.call('getuserDeviceInfo', '');
    userDeviceInfo = JSON.parse(value);
  }

  if (userDeviceInfo && userDeviceInfo.mac) {
    mac = userDeviceInfo.mac;
    version = userDeviceInfo.version;
    deviceModel = userDeviceInfo.deviceModel;
    systemVersion = userDeviceInfo.systemVersion;
  }
  
  Object.assign(config.headers, {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Mac': mac || 'from-web',
    'X-Lang': getLocalStorage('language') || 'en',
    'X-Platform': platform,
    'X-Ver': version || '',
    'X-Mobilephone': deviceModel,
    'X-SysVersion': systemVersion,
    'X-Ts': parseInt((new Date().getTime() / 1000) + ''),
  })
  
  if (parseInt(uid) > 0 && accesstoken && accesstoken != '') {
    config.headers['X-Uid'] = uid;
    config.headers['X-Token'] = accesstoken || '';
  }

  return config
})

instance.interceptors.response.use(async (res) => {
  res = res || {}
  const { data: rawData = {} } = res
  // const config: AxiosConfig = res.config
  const { code } = rawData

  // 其它错误
  if (code !== 0) {
    // if (rawData.msg && rawData.requestId && NODE_ENV !== 'production') {
    //   rawData.msg += `\nrequestId: ${rawData.requestId}`
    // }

    // if (config.showMessage && rawData.msg) {
    //   Toast.show(rawData.msg)
    // }
    return Promise.reject(rawData)
  }

  return rawData
})

/**
 * 处理列表数据，是否有下一页，是否是空列表等
 * @param useRequestData
 * @param params
 * @returns
 */
function handleExtraData(useRequestData: any, options: any = {}) {
  const { data, error } = useRequestData
  const { pageNum, pages, data: resData, extend, msg, code } = data || {}

  Object.assign(useRequestData, {
    hasNextPage: true,
    isDataEmpty: false,
    data: resData,
    extend: extend || {},
    msg: msg || (error && error.msg) || '',
  })

  if (isExist(pages) && !pages) {
    useRequestData.isDataEmpty = true
  }
  if (
    (Array.isArray(resData) && pageNum >= pages) ||
    error ||
    (code === 0 && !resData)
  ) {
    useRequestData.hasNextPage = false
  }

  // 简化runAsync返回的数据
  const runAsync = useRequestData.runAsync
  useRequestData.runAsync = async function wrapRunAsync(...args: any[]) {
    const [pageNum, pages] = args
    const [err, res] = await to(runAsync(...args))
    const DEFAULT_RESDATA = pageNum || pages ? [] : {}
    const resData = (res && res.data) || DEFAULT_RESDATA
    useRequestData.data = resData
    useRequestData.error = err

    if (err) {
      console.warn(err.msg)
    }

    return resData
  }

  if (options.isLastRequest) {
    useRequestData.run = onlyLastRequest(useRequestData.run)
    useRequestData.runAsync = onlyLastRequest(useRequestData.runAsync)
  }

  return useRequestData
}

/**
 * 处理useRequest get请求
 * @param url
 * @param data
 * @param options
 * @returns
 */
function useGet(
  url: string,
  data: object = {},
  options: object = {}
): UseRequestHttpResult {
  const totalOptions = { ...baseRequestOptions, ...options }
  let totalData = { ...data }
  const useRequestData = useRequest((newData = {}) => {
    totalData = { ...totalData, ...newData }
    return instance({
      url,
      method: 'get',
      params: totalData,
      ...totalOptions,
    })
  }, totalOptions)

  return handleExtraData(useRequestData, totalOptions)
}

/**
 * 处理useRequest post请求
 * @param url
 * @param data
 * @param options
 * @returns
 */
function usePost(
  url: string,
  data: object = {},
  options: object = {}
): UseRequestHttpResult {
  const totalOptions = { ...baseRequestOptions, ...options }
  let totalData = { ...data }
  const useRequestData = useRequest((newData = {}) => {
    totalData = { ...totalData, ...newData }
    return instance({
      url,
      method: 'post',
      data: totalData,
      ...totalOptions,
    })
  }, totalOptions)

  return handleExtraData(useRequestData, totalOptions)
}

/**
 * 普通post请求
 * @param url
 * @param data
 * @param options
 * @returns
 */
function get<T>(
  url: string,
  data: object = {},
  options: object = {}
): Promise<T> {
  const totalOptions = { ...baseRequestOptions, ...options }
  return instance({
    url,
    method: 'get',
    params: data,
    ...totalOptions,
  })
}

/**
 * 普通post请求
 * @param url
 * @param data
 * @param options
 * @returns
 */
function post<T>(
  url: string,
  data: object = {},
  options: object = {}
): Promise<T> {
  const totalOptions = { ...baseRequestOptions, ...options }

  return instance({
    url,
    method: 'post',
    data: data,
    ...totalOptions,
  })
}

// 暴露的http请求实例
const http = {
  useGet,
  usePost,
  get,
  post,
}

export default http
