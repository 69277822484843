interface Locale {
  [key: string]: {
    en_US: string;
    ar_SA: string;
    tr_TR: string;
  };
}

const locales: Locale = {
  语言: {
    en_US: 'English',
    ar_SA: 'لغة',
    tr_TR: 'dil',
  },
  '请求地址异常，请检查': {
    en_US: 'The request address is abnormal, please check',
    ar_SA: 'عنوان الطلب غير طبيعي، يرجى التحقق',
    tr_TR: 'İstek adresi anormal, lütfen kontrol edin',
  },
  '请求网络异常，请稍后': {
    en_US: 'Request network exception, please wait.',
    ar_SA: 'طلب استثناء الشبكة، يرجى الانتظار.',
    tr_TR: 'Ağ istisnası isteyin, lütfen bekleyin.',
  },
  请求失败: {
    en_US: 'Request failed',
    ar_SA: 'فشل الطلب',
    tr_TR: 'İstek başarısız oldu',
  },
  公会: {
    en_US: 'Angency',
    ar_SA: 'يتبع CY',
    tr_TR: 'Acente',
  },
  搜索: {
    en_US: 'Search',
    ar_SA: 'يبحث',
    tr_TR: 'aramak',
  },
  '搜索姓名/ID': {
    en_US: 'Search for name / ID',
    ar_SA: 'يبحث عن الاسم / الهوية',
    tr_TR: 'Isim / Kimlik aramak',
  }
};

export default locales;
