import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { useDispatchActions, setStoreState } from './utils'
import { storageUtil } from '@/utils'

export interface UserInfo {
  uid: string
  nikeName: string
  duid: string
  [key: string]: any
}

const initialState: UserInfo = {
  uid: '',
  nikeName: '',
  duid: '',
}

export const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      const payload = action.payload
      setStoreState(state, payload)
      storageUtil.setLocalStorage('userInfo', JSON.stringify(payload))
    },
  },
})

export const { setUserInfo: userInfoActionCreator } = slice.actions
export const reducer = slice.reducer

export default function useCorpInfoStore() {
  const state = useSelector((state: RootState) => state.userInfo)
  const storageState = storageUtil.getLocalStorage('userInfo') || {}
  const actionPool = useDispatchActions(slice.actions)
  const totalState = { ...state, ...storageState }

  return {
    corpInfo: totalState,
    ...actionPool,
  }
}
