// @ts-ignore
import { useSetState, useMount } from 'ahooks'
import styles from './index.module.less';
import apis from '@/apis'
import { Toast } from 'antd-mobile'
import { utils, i18n } from '@/utils';
import iconSearch from '@/assets/img/icon-search@2x.webp';

const { user: userApi } = apis
const { to } = utils
const { t } = i18n
function Angency() {
  async function getMyAccountInfo () {
    Toast.show({
      icon: 'loading',
      content: 'loading…',
      duration: 0,
    })
    const [err, res] = await to(
      userApi.getMyAccountInfo({}),
    )

    Toast.clear()
    if (err) return

    console.log(res)
  }

  useMount(() => {
    document.title = t('公会')
    getMyAccountInfo()
  })

  return (
    <div className={styles['page-wrap']}>
      <div className={styles['search-wrap']}>
        <div className={styles['search-placeholder']}></div>
        <div className={styles['search-bd']}>
          <div className={styles['input-wrap']}>
            <div className={styles['icon-search']}>
              <img src={iconSearch} />
            </div>
            <input type="text" placeholder={t('搜索姓名/ID')} />
          </div>
          <div className={styles['text-wrap']}>{t('搜索')}</div>
        </div>
      </div>
      <div className={styles['card-wrap']}></div>
    </div>
  )
}

export default Angency

