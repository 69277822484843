// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import VConsole from 'vconsole'
import { ConfigProvider } from 'antd-mobile'
import router from '@/router'
import { store } from '@/stores/store'
import i18n, { getAntLangFile, getI18nLang } from '@/utils/i18n'
import { utils, lang, storageUtil } from '@/utils';
import './styles/index.less'

// const { NODE_ENV } = process.env;
// 判断是否是生产环境的条件，打包是生产环境，或者app传的环境是生产环境
const isProduction = NODE_ENV === 'production'
if (!isProduction) {
  // 不是生产环境，并且不是本地开发，显示控制台
  new VConsole()
}
const { setLocalStorage } = storageUtil;
const { setUidToken, getUrlParameters } = utils;
const { getlang } = lang;

// 缓存token、uid、language
(async () => {
  const urlQuery: { [key: string]: string } = getUrlParameters(window.location.href) || {};
  await setUidToken(urlQuery);
  const currentLanguage = await getlang(urlQuery.language);
  setLocalStorage('language', currentLanguage);
  // @ts-ignore
  window.language = currentLanguage;

  const i18nLang = getI18nLang()
  i18n.changeLanguage(i18nLang)
  console.log('i18nLang', i18nLang)
  console.log('App 设置', currentLanguage);
})();

createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <ConfigProvider locale={getAntLangFile()}>
        <RouterProvider router={router}></RouterProvider>
    </ConfigProvider>
  </Provider>,
)