import App from '@/pages/App'
import Angency from '@/pages/Angency'

export interface Route {
  path: string
  name: string
  element: JSX.Element
  state?: any
  [key: string]: any
}

export interface RoutesMap {
  [key: string]: string
}

const routes: Route[] = [
  {
    path: '/',
    name: 'App',
    title: 'Hoby',
    element: (<App />),
  },
  {
    path: '/angency',
    name: 'Angency',
    title: '公会列表',
    element: (<Angency />),
  }
]

export default routes
